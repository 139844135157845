import React, { useEffect, useRef } from "react"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import { isMobile } from "react-device-detect"

import Carousel from "../components/carousel"
import LinkSection from "../components/link-section"
import Footer from "../components/footer"
import { useOnLoadImages } from "../hooks/useOnLoadImages"
import Preloader from "../components/preloader"

const OfficePage: React.FC = () => {
  useEffect(() => {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "page_view", {
        page_title: "office",
        page_path: "/office",
        send_to: "G-LB7ZYCKNWC",
      })
    }
  }, [])
  const wrapperRef = useRef<HTMLDivElement>(null)
  const imagesLoaded = useOnLoadImages(wrapperRef)
  const data = useStaticQuery(graphql`
    query MyQuery {
      prismicOfficePage {
        data {
          collaborators_image {
            url(imgixParams: { width: 640 })
          }
          collaborators_image_desktop {
            url(imgixParams: { width: 1024 })
          }
          body {
            ... on PrismicOfficePageDataBodyTextCarousel {
              items {
                slide_text {
                  richText
                }
              }
            }
            ... on PrismicOfficePageDataBodyLinkSection {
              primary {
                heading_text {
                  text
                }
                link {
                  url
                }
                link_text {
                  text
                }
              }
            }
            ... on PrismicOfficePageDataBodyImageCarousel {
              items {
                slide_image {
                  url(imgixParams: { width: 1280 })
                }
                slide_text {
                  richText
                }
              }
            }
          }
        }
      }
    }
  `)

  const collaboratorsImage = data.prismicOfficePage.data.collaborators_image
  const collaboratorsImageMd =
    data.prismicOfficePage.data.collaborators_image_desktop
  const slices = data.prismicOfficePage.data.body

  const slides1 = slices[0].items.map(item => {
    return { prismicRichText: item.slide_text.richText }
  })
  const linkSection1 = slices[1].primary
  const slides2 = slices[2].items.map(item => {
    return {
      prismicRichText: item.slide_text.richText,
      imageUrl: item.slide_image.url,
    }
  })
  const linkSection2 = slices[3].primary
  const slides3 = slices[4].items.map(item => {
    return {
      prismicRichText: item.slide_text.richText,
      imageUrl: item.slide_image.url,
    }
  })
  const linkSection3 = slices[5].primary
  const linkSection4 = slices[6].primary

  return (
    <div ref={wrapperRef}>
      {!imagesLoaded && <Preloader />}
      <Layout>
        <Carousel slides={slides1} fade={false} justText />

        <LinkSection
          sectionText={linkSection1.heading_text.text}
          linkPath={linkSection1.link.url}
          linkText={linkSection1.link_text.text}
        />

        <Carousel slides={slides2} fade={true} />

        <LinkSection
          sectionText={linkSection2.heading_text.text}
          linkPath={linkSection2.link.url}
          linkText={linkSection2.link_text.text}
        />

        <Carousel slides={slides3} fade={true} />

        <LinkSection
          sectionText={linkSection3.heading_text.text}
          linkPath={linkSection3.link.url}
          linkText={linkSection3.link_text.text}
        />

        <img
          src={isMobile ? collaboratorsImage.url : collaboratorsImageMd.url}
          alt="collaborators"
          className="w-full h-auto"
        />

        <LinkSection
          sectionText={linkSection4.heading_text.text}
          linkPath={linkSection4.link.url}
          linkText={linkSection4.link_text.text}
        />

        <Footer />
      </Layout>
    </div>
  )
}

export default OfficePage
