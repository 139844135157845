import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import { RichText, RichTextBlock } from "prismic-reactjs";
const Footer: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicFooter {
        data {
          contact_us_header {
            text
          }
          follow_us_header {
            text
          }
          address {
            richText
          }
          contact_email
          contact_phone
          social_media_icons {
            icon {
              fluid(maxWidth: 100) {
                ...GatsbyPrismicImageFluid
              }
            }
            url
          }
        }
      }
    }
  `);
  const {
    contact_us_header,
    follow_us_header,
    address,
    contact_email,
    contact_phone,
    social_media_icons,
  } = data.prismicFooter.data;
  return (
    <>
      <div className="w-full bg-black">
        <div className="flex w-full py-8 pl-6 mx-auto leading-tight text-white md:max-w-95% xl:max-w-8/10 2xl:max-w-7/10 lg:pl-16 xl:pl-0">
          <div className="flex-1">
            <div className="mb-6 text-3xl font-semibold sm:text-5xl">
              {contact_us_header.text}
            </div>
            <div className="flex flex-col md:flex-row">
              <div className="flex-1 mb-4">
                <RichText render={address.richText} />
              </div>
              <div className="flex-1">
                {contact_email}
                <br />
                {contact_phone}
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div className="pl-6 mb-6 text-3xl font-semibold lg:pl-16 xl:pl-33% sm:text-5xl">
              {follow_us_header.text}
            </div>
            <div className="flex flex-wrap justify-start pl-6 lg:pl-16 xl:pl-33%">
              {social_media_icons.map((social_icon, i) => {
                const clickHandler = () => {
                  if (!social_icon.url) {
                    return;
                  }

                  window.location.href = social_icon.url;
                };
                return (
                  <div
                    key={i}
                    className="cursor-pointer footer-icon"
                    onClick={clickHandler}
                  >
                    <Img fluid={social_icon.icon.fluid} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="px-6 py-4 text-xs leading-5 text-center text-black bg-white">
        <div className="sm:inline-block sm:pr-1">Privacy Policy</div>
        <div className="sm:inline-block">
          © 2020 <span style={{ wordSpacing: "-1px" }}>RAD DAR</span> Limited.
          All rights reserved
        </div>
      </div>
    </>
  );
};

export default Footer;
