import React from "react"
import { Link } from "gatsby"

interface LinkSectionProps {
  sectionText: string
  linkText: string
  linkPath: string
}

const LinkSection: React.FC<LinkSectionProps> = ({
  sectionText,
  linkText,
  linkPath,
}) => {
  return (
    <div className="px-2 mx-auto mb-6 text-center text-black sm:px-4 lg:px-10">
      <div className="pt-10 pb-8 font-semibold leading-normal text-xxl sm:text-4xl">
        {sectionText}
      </div>
      <Link to={linkPath}>
        <div className="inline-block text-base no-underline whitespace-no-wrap border-b border-black sm:text-xxl hover:font-semibold">
          {linkText}
          <span> &#8594;</span>
        </div>
      </Link>
    </div>
  )
}

export default LinkSection
